import { createSlice } from "@reduxjs/toolkit";

export const logementSlice = createSlice({
  name: "logement",
  initialState: {
    isRefresh: false,
    isEmpty: false,
    isUsed: false,
    lit: 0,
    managementType: ""
  },
  reducers: {
    setRefresh: (state, { payload }) => {
      state.isRefresh = payload;
      return state;
    },

    setUpdate: (state, { payload }) => {
      state.lit = payload.lit;
      state.managementType = payload.managementType;
      return state;
    },

    setIcal: (state, { payload }) => {
      state.isEmpty = payload;
      return state;
    },

    showIcalDialog: (state, { payload }) => {
      state.isEmpty = payload.isEmpty
      state.isSuccess = payload.isSuccess
      state.isUsed = payload.isUsed
    },

  },
});

export const { setRefresh, setUpdate, setIcal, showIcalDialog } = logementSlice.actions;
export default logementSlice.reducer;
