import { Tag } from "antd";
import { jwtDecode } from 'jwt-decode';

// export const webAppUrl = "https://app.preprod.kliner.com";
// export const API_ROOT = urls[process.env.NODE_ENV];
export const API_ROOT = "https://api.preprod.kliner.com";
//export const API_ROOT = "http://localhost:8000";

export const TAX_EXEMPTED_COUNTRIES = ["Royaume-Uni"];

export const getUser = () => {
  const token = JSON.parse(localStorage.getItem("access_token"));
  if (token) {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  }
};

export const remuneration = (prestation) => {
  let sum = 0;
  sum = prestation?.cout_menage / 100.2;
  return sum;
};

export const customStatus = (status) => {
  switch (status) {
    case "Nouveau":
      return "#5C198E";
    case "Programmation en cours":
      return "#404040";
    case "Assigné":
    case "Programmé":
      return "#5C198E";
    case "En cours":
      return "#fa7f0d";
    case "Terminé":
      return "#4e8900";
    case "Annulé":
      return "#ff0000e3";
    case "En attente":
      return "#cd0e00";
    case "Non effectué":
      return "#000000";
    default:
      break;
  }
};

export const billingStatusTagColour = (status) => {
  switch (status) {
    case "cashed":
      return "#fa7f0d";
    case "pending":
      return "#5C198E";
    case "paid":
      return "#4e8900";
    default:
      break;
  }
};

export const assignmentStatus = (status) => {
  switch (status) {
    case "Assignation en cours":
      return (
        <Tag
          color={`#FBE288`}
          style={{ color: "black", padding: "0px 18px", marginLeft: "20px" }}
        >
          {status}
        </Tag>
      );
    case "Offre refusée":
      return (
        <Tag
          color={`#B00004`}
          style={{ padding: "0px 18px", marginLeft: "20px" }}
        >
          {status}
        </Tag>
      );
    default:
      break;
  }
};



export const coutHorsTax = (profil, data) => {
  if (!data) return 0;
  if (profil.toLowerCase() === "particulier") {
    return ((data?.details_info.cout_total || 0) / 1.1).toFixed(2);
  }
  return data?.details_info.cout_total || 0;
};


export const coutTTC = (profil, data, address) => {
  if (!data) return 0;
  if (profil.toLowerCase() === "particulier" || TAX_EXEMPTED_COUNTRIES.includes(address?.pays)) {
    return data?.details_info.cout_total || 0;
  }
  return ((data?.details_info.cout_total || 0) * 1.2).toFixed(2);
};

export const subPlanTagColor = (plan) => {
  switch (plan?.reference) {
    case "autonome":
      return (
        <Tag
          color={`#FBE288`}
          style={{ color: "black", padding: "0px 18px", marginLeft: "20px" }}
        >
          {plan?.name}
        </Tag>
      );
    case "confort":
      return (
        <Tag
          color={`#B00004`}
          style={{ padding: "0px 18px", marginLeft: "20px" }}
        >
          {plan?.name}
        </Tag>
      );
    default:
      break;
  }
};

export const convertToArray = (value) => {
  if (value) {
    const array = JSON.parse(value);
    console.log("array:", array);
    return array;
  }
};

export const address = (value) => {
  const data = JSON.parse(value);
  const full_address = `${data.street_number} ${data.street}, ${data.country}`;
  return full_address;
};

export const coord = (value) => {
  const data = JSON.parse(value);
  const coord = { lat: data.latitude, lng: data.longitude };
  return coord;
};

export const coutMenageClassique = (cout_menage, profil) => {
  if (!cout_menage)
    return 0;
  let sum = 0;
  sum = (parseFloat(cout_menage.cout_menage_classique) +
    parseFloat(cout_menage.cout_kit_accueil) +
    parseFloat(cout_menage.cout_kit_linge) +
    parseFloat(cout_menage.cout_produit_menager));
  sum = sum * (profil === "particulier" ? 1.1 : 1);
  return sum.toFixed(2);
};


export const coutTotalMenageProfondeur = (cout_menage, profil) => {
  if (!cout_menage)
    return 0;
  let sum = 0;
  sum =
    parseFloat(cout_menage.cout_menage_profondeur) +
    parseFloat(cout_menage.cout_kit_accueil) +
    parseFloat(cout_menage.cout_kit_linge) +
    parseFloat(cout_menage.cout_produit_menager);
  sum = sum * (profil === "particulier" ? 1.1 : 1);
  return sum.toFixed(2);
};

export const tarifKitAccueil = (cart) => {
  var items = [];
  cart.forEach((child) => {
    items.push({
      total: child.prix * child.quantite,
    });
  });
  var total = 0;
  for (var i = 0; i < items.length; i++) {
    total += items[i].total;
  }
  return total;
};

// CONVERT MINUTE TO HEURE
export const convertMinutes = (minutes) => {
  if (minutes === null || minutes === 0 || isNaN(minutes)) return "...";
  let h = Math.trunc(minutes / 60);
  let m = minutes % 60;

  let hDisplay = h > 0 ? h.toFixed(0) + (h === 1 ? " h " : " h ") : "0 h ";
  let mDisplay =
    m > 0 ? m.toFixed(0) + (m === 1 ? " min " : " min ") : "00 min";
  return hDisplay + mDisplay;
};

export const convertDateStringToTimeString = (dateString, start = "start") => {
  if (dateString) {
    const date = dateString.split("T");
    const hours = date[1].split(":")[0] === "null" || date[1].split(":")[0] === null ? (start === "start" ? "10" : "16") : date[1].split(":")[0];
    const minutes = date[1].split(":")[1].split(".")[0] ?? "00";
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  }
  return "";
};
export const convertDateStringToTimeStringComplete = (dateString) => {
  if (dateString) {
    const date = dateString.split("T");
    const hours = date[1].split(":")[0] === "null" ? "10" : date[1].split(":")[0];
    const minutes = date[1].split(":")[1].split(".")[0] ?? "00";
    const day = date[0].split("-")[2];
    const month = parseInt(date[0].split("-")[1]);
    const year = date[0].split("-")[0];
    const formattedTime = `${day.toString()} ${monthList[month]} ${year.toString()} à ${hours.toString()}:${minutes.toString()}`;
    return formattedTime;
  }
  return "";
};

export const convertDateStringToTimeStringDateOnly = (dateString, addDay = false) => {
  if (dateString) {
    let dayName = "";
    if (addDay) {
      const completeDate = new Date(dateString);
      const options = { weekday: 'long' };
      dayName = new Intl.DateTimeFormat('fr-FR', options).format(completeDate);
      dayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    }
    const date = typeof dateString === 'string' ? dateString.split("T") : dateString.toISOString().split("T");
    const day = date[0].split("-")[2];
    const month = parseInt(date[0].split("-")[1]);
    const year = date[0].split("-")[0];
    return `${dayName} ${day.toString()} ${monthList[month]} ${year.toString()}`;
  }
  return "";
};

export const addColonToTimestamp = (timestamp) => {
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}):(\d{1})(:\d{2}(\.\d+)?Z)/;
  const formattedTimestamp = timestamp.replace(regex, "$1:0$2$3");
  return formattedTimestamp;
};

export const addColonToTime = (timeString) => {
  const regex = /(\d+) h (\d+) min/;
  const formattedTime = timeString.replace(regex, (match, hours, minutes) => {
    const paddedMinutes = minutes.length === 1 ? `0${minutes}` : minutes;
    return `${hours} h ${paddedMinutes} min`;
  });
  return formattedTime;
};

export const checkControl = (prestation) => {
  const qc = prestation?.quality_control;
  const kitLingeStatus = prestation?.logement.statut_kit_linge;

  if (
    qc &&
    qc.qualite_du_menage_conforme &&
    (kitLingeStatus ? qc.linge_amenities_deposes : true)
  ) {
    return <img src="/assets/img/control-Ok.png" width="20px" alt="kliner" />;
  } else if (
    qc &&
    qc.aide_menagere_assignee &&
    (kitLingeStatus ? qc.linge_amenities_prets : true) &&
    qc.presence_confirmee
  ) {
    return (
      <img src="/assets/img/control-Ok-before.png" width="20px" alt="kliner" />
    );
  }
  return "";
};


export const dateToIso = (date) => {
  const dateObject = new Date(date);
  const isoDateString = dateObject.toISOString();
  return isoDateString
};

export const allowedRoles = [
  "administrateur",
  "support",
  "business developer",
  "partner manager",
  "logisticien",
  "analyste",
  "lecture seule",
  "customer success"
];

export const isExpired = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

export const monthList = ["", "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"];
export const countryCodesMap = {
  AC: "+247",
  AD: "+376",
  AE: "+971",
  AF: "+93",
  AG: "+1268",
  AI: "+1264",
  AL: "+355",
  AM: "+374",
  AO: "+244",
  AQ: "+672",
  AR: "+54",
  AS: "+1684",
  AT: "+43",
  AU: "+61",
  AW: "+297",
  AX: "+35818",
  AZ: "+994",
  BA: "+387",
  BB: "+1246",
  BD: "+880",
  BE: "+32",
  BF: "+226",
  BG: "+359",
  BH: "+973",
  BI: "+257",
  BJ: "+229",
  BL: "+590",
  BM: "+1441",
  BN: "+673",
  BO: "+591",
  BQ: "+599",
  BR: "+55",
  BS: "+1242",
  BT: "+975",
  BV: "+47",
  BW: "+267",
  BY: "+375",
  BZ: "+501",
  CA: "+1",
  CC: "+61",
  CD: "+243",
  CF: "+236",
  CG: "+242",
  CH: "+41",
  CI: "+225",
  CK: "+682",
  CL: "+56",
  CM: "+237",
  CN: "+86",
  CO: "+57",
  CR: "+506",
  CU: "+53",
  CV: "+238",
  CW: "+599",
  CX: "+61",
  CY: "+357",
  CZ: "+420",
  DE: "+49",
  DJ: "+253",
  DK: "+45",
  DM: "+1767",
  DO: "+1809",
  DZ: "+213",
  EC: "+593",
  EE: "+372",
  EG: "+20",
  EH: "+212",
  ER: "+291",
  ES: "+34",
  ET: "+251",
  FI: "+358",
  FJ: "+679",
  FK: "+500",
  FM: "+691",
  FO: "+298",
  FR: "+33",
  GA: "+241",
  GB: "+44",
  GD: "+1473",
  GE: "+995",
  GF: "+594",
  GG: "+441481",
  GH: "+233",
  GI: "+350",
  GL: "+299",
  GM: "+220",
  GN: "+224",
  GP: "+590",
  GQ: "+240",
  GR: "+30",
  GS: "+500",
  GT: "+502",
  GU: "+1671",
  GW: "+245",
  GY: "+592",
  HK: "+852",
  HM: "+672",
  HN: "+504",
  HR: "+385",
  HT: "+509",
  HU: "+36",
  ID: "+62",
  IE: "+353",
  IL: "+972",
  IM: "+441624",
  IN: "+91",
  IO: "+246",
  IQ: "+964",
  IR: "+98",
  IS: "+354",
  IT: "+39",
  JE: "+441534",
  JM: "+1876",
  JO: "+962",
  JP: "+81",
  KE: "+254",
  KG: "+996",
  KH: "+855",
  KI: "+686",
  KM: "+269",
  KN: "+1869",
  KP: "+850",
  KR: "+82",
  KW: "+965",
  KY: "+1345",
  KZ: "+7",
  LA: "+856",
  LB: "+961",
  LC: "+1758",
  LI: "+423",
  LK: "+94",
  LR: "+231",
  LS: "+266",
  LT: "+370",
  LU: "+352",
  LV: "+371",
  LY: "+218",
  MA: "+212",
  MC: "+377",
  MD: "+373",
  ME: "+382",
  MF: "+590",
  MG: "+261",
  MH: "+692",
  MK: "+389",
  ML: "+223",
  MM: "+95",
  MN: "+976",
  MO: "+853",
  MP: "+1670",
  MQ: "+596",
  MR: "+222",
  MS: "+1664",
  MT: "+356",
  MU: "+230",
  MV: "+960",
  MW: "+265",
  MX: "+52",
  MY: "+60",
  MZ: "+258",
  NA: "+264",
  NC: "+687",
  NE: "+227",
  NF: "+672",
  NG: "+234",
  NI: "+505",
  NL: "+31",
  NO: "+47",
  NP: "+977",
  NR: "+674",
  NU: "+683",
  NZ: "+64",
  OM: "+968",
  PA: "+507",
  PE: "+51",
  PF: "+689",
  PG: "+675",
  PH: "+63",
  PK: "+92",
  PL: "+48",
  PM: "+508",
  PN: "+64",
  PS: "+970",
  PT: "+351",
  PW: "+680",
  PY: "+595",
  QA: "+974",
  RE: "+262",
  RO: "+40",
  RS: "+381",
  RU: "+7",
  RW: "+250",
  SA: "+966",
  SB: "+677",
  SC: "+248",
  SD: "+249",
  SE: "+46",
  SG: "+65",
  SH: "+290",
  SI: "+386",
  SJ: "+47",
  SK: "+421",
  SL: "+232",
  SM: "+378",
  SN: "+221",
  SO: "+252",
  SR: "+597",
  SS: "+211",
  ST: "+239",
  SV: "+503",
  SX: "+599",
  SY: "+963",
  SZ: "+268",
  TC: "+1649",
  TD: "+235",
  TF: "+262",
  TG: "+228",
  TH: "+66",
  TJ: "+992",
  TK: "+690",
  TL: "+670",
  TM: "+993",
  TN: "+216",
  TO: "+676",
  TR: "+90",
  TT: "+1868",
  TV: "+688",
  TW: "+886",
  TZ: "+255",
  UA: "+380",
  UG: "+256",
  UM: "+1",
  US: "+1",
  UY: "+598",
  UZ: "+998",
  VA: "+379",
  VC: "+1784",
  VE: "+58",
  VG: "+1284",
  VI: "+1340",
  VN: "+84",
  VU: "+678",
  WF: "+681",
  WS: "+685",
  XK: "+383",
  YE: "+967",
  YT: "+262",
  ZA: "+27",
  ZM: "+260",
  ZW: "+263"
};

export const eventText = (pretation) => {
  switch (pretation?.events_log_action) {
    case "prestation.create":
      return <div className="libelle">La prestation a été créée par <span>{pretation?.user_firstname}</span></div>;
    case "prestation.reset_info":
      return <div className="libelle"></div>;
    case "prestation.update":
      return <div className="libelle">Mise à jour de la prestation par <span>{pretation?.user_firstname}</span></div>;
    case "prestation.started":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a démarré la prestation.</div>;
    case "prestation.ended":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a terminé la prestation.</div>;
    case "prestation.upload_picture":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a téléchargé des images</div>;
    case "prestation.update.responsible":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a designé <span>Brice</span> comme responsable</div>;
    case "prestation.update.aide_menagere":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a choisit une autre aide ménagère qui est ...</div>;
    case "prestation.create.aide_menagere":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a designé <span>Brice</span> comme aide ménagère</div>;
    case "prestation.update.partner_prestation_price":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a mise à jour le prix de la prestation</div>;
    case "prestation.revert.partner_prestation_price":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> à annulé le changement sur le prix de la prestation</div>;
    case "prestation.quality.create":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a demarrer un cocntrole de qualité sur la prestation</div>;
    case "prestation.quality.create.bo_star":
      return <div className="libelle">Un agent Kliner à démarré l'évaluation de la prestation.</div>;
    case "prestation.quality.update":
      return <div className="libelle"><span>{pretation?.user_firstname}</span> a mise à jour le controle de qualité de la prestation.</div>;
    default:
      return pretation?.events_log_action;
  }
};

export const handleConvert = (minute) => {
  const minutes = parseInt(minute, 10);
  if (!isNaN(minutes)) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return (`${hours}h ${remainingMinutes}`);
  }
  return "";
};