import React, { useEffect, useState } from 'react';
import { Dropdown, Row, Col, Table, Input, Select, Calendar, Badge, Spin, Space, Avatar } from 'antd';
import { CaretLeftOutlined, CaretRightOutlined, EditOutlined, PlusCircleOutlined, ReloadOutlined, SearchOutlined } from '@ant-design/icons';
import { IoAdd, IoCalendarClear } from "react-icons/io5";
import dayjs from 'dayjs';
import LogementCreate from "../logements/create";
import ProgrammerManuellementModalView from "./modal/programmer-manuellement";
import PlanningDetail from "./planning_detail";
import { setRefresh } from "../logements/services/logement-slice";
import { useDispatch, useSelector } from "react-redux";
import { useLogementCreateMutation } from "../logements/services/logement-api";
import { API_ROOT, convertDateStringToTimeString, monthList } from "../../utils/global-var";
import moment from 'moment';
import PlanningSuccess from './modal/planning-success';
import { usePrestationFetchMutation } from './services/planning-api';
import "./styles/style.scss"
import "./styles/styleMax1020.scss"


function PlanningPage() {
    let now = new Date();
    const [showModal, setShowModal] = useState(false);
    const [showModalProgrammerService, setShowModalProgrammerService] = useState(false);
    const [showModalProgrammerValid, setShowModalProgrammerValid] = useState(true);
    const [showModalProgrammeApercu, setShowModalProgrammeApercu] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(monthList[parseInt(new Date().getMonth() + 1)] + " " + (new Date().getFullYear()));
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [prestations, setPrestations] = useState([]);
    const [prestationId, setPrestationId] = useState();
    const [aidemenageres, setAidemenageres] = useState([]);
    const [services, setServices] = useState([]);
    const [status, setStatus] = useState([]);
    const [startDate, setStartDate] = useState(new Date().getFullYear() + "-" + (new Date().getMonth()).toString().padStart(2, '0') + "-01");
    const [endDate, setEndDate] = useState(new Date().getFullYear() + "-" + (new Date().getMonth() + 2).toString().padStart(2, '0') + "-01");
    const [selectedTable, setSelectedTable] = useState([]);
    const { user } = useSelector((state) => state.auth);
    const [prestationFetch] = usePrestationFetchMutation()
    const [isLoading, setIsLoading] = useState(false);
    const [logementCreate] = useLogementCreateMutation();
    const [logementId, setLogementId] = useState();
    const dispatch = useDispatch();
    const [error, setError] = useState(false);
    const { isSuccess, isRefresh } = useSelector((state) => state.planning);
    const [events, setEvents] = useState({});

    useEffect(() => {
        if (prestations.data?.length !== 0 && prestations?.data !== null) {
            setSelectedTable(getListData(selectedMonth));
        }
    }, [prestations.data, selectedMonth]);
    //console.log("startDate:", startDate);
    //console.log("endDate:", endDate);
    //console.log(selectedTable);

    const handleFetchPrestations = async (data) => {
        setIsLoading(true)
        prestationFetch(data).unwrap()
            .then((res) => {
                setPrestations(res.data)
                setIsLoading(false)
                setAidemenageres(res.filter.aide_menageres)
                setServices(res.filter.services)
                setStatus(res.filter.status)
                dispatch(setRefresh(false));
            })
            .catch(() => setIsLoading(false));
    };



    useEffect(() => {
        const data = ({ "start_date": startDate, "end_date": endDate })
        handleFetchPrestations(data)
    }, [startDate, endDate, selectedMonth, showModalProgrammeApercu]);

    useEffect(() => {
        const data = ({ "start_date": startDate, "end_date": endDate })
        isRefresh &&
            handleFetchPrestations(data);
    }, [isRefresh]);


    const transformData = (data) => {
        const events = {};
        data?.forEach((event) => {
            // console.log("event ===>", event);
            const dateStr = moment(event.creneau_intervention_debut).format('YYYY-MM-DD');
            if (!events[dateStr]) events[dateStr] = [];
            events[dateStr].push({
                key: event.id,
                logement: event.logement?.libelle,
                region: event.logement?.ville,
                prestation: event.service,
                horaire: event.duree_prestation,
                status: event.status,
                creneau_intervention_debut: event.creneau_intervention_debut,
                creneau_intervention_fin: event.creneau_intervention_fin,
                intervenant: {
                    firstname: event.aide_menagere?.firstname,
                    avatar: event.aide_menagere?.avatar,
                },
            });
        });
        return events;
    };


    useEffect(() => {
        const eventsData = transformData(prestations);
        setEvents(eventsData);
    }, [prestations]);


    useEffect(() => {
        setCurrentMonth(monthList[selectedMonth.month() + 1] + " " + selectedMonth.year());
        setSelectedTable(getListData(selectedMonth));
    }, [selectedMonth]);


    const onPanelChange = (value, mode) => {
        console.log(value.format('YYYY-MM-DD'), mode);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    console.log(selectedTable);

    const handleCreate = async () => {
        const data = ({ libelle: "Logement de " + user?.firstname, user: user.id })
        setIsLoading(true);
        logementCreate(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setShowModal(true)
                setLogementId(res.id);
                dispatch(setRefresh(true));
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }

    const columns = [
        {
            title: 'LOGEMENT',
            key: "logement",
            render: (record) =>
                <div onClick={() => (setShowModalProgrammeApercu(true), setPrestationId(record.key))} style={{ cursor: "pointer" }}>
                    <div className="titleTable color-213856 sans-pro-regular">{record.logement}</div>
                    <div className="subtitleTable sans-pro-regular color-A7A7A7">{record.region}</div>
                </div>,
        },
        {
            title: 'PRESTATION',
            key: "prestation",
            render: (record) => <div onClick={() => (setShowModalProgrammeApercu(true), setPrestationId(record.key))} style={{ cursor: "pointer" }}>
                <div className="titleTable color-213856 sans-pro-regular">{record.prestation}</div>
                <div className="subtitleTable sans-pro-regular color-A7A7A7">Entre {convertDateStringToTimeString(record?.creneau_intervention_debut)} et {convertDateStringToTimeString(record?.creneau_intervention_fin, "end")}</div>
            </div>,
        },
        {
            title: 'INTERVENANT',
            key: "intervenant",
            render: (record) => <>
                <div className="imageTableContent">
                    <Avatar className="imageTable" src={`${API_ROOT}/${record.intervenant.avatar}`} />
                </div>
            </>,
        },
        {
            title: 'STATUT',
            key: "status",
            render: (record) => <div className="badgeTable">
                {(record.status === "Programmé") && (<Badge color="#333333" count={"À faire"} />)}
                {(record.status === "En cours") && (<Badge color="#FDAC3D" count={"En cours"} />)}
                {(record.status === "Annulé") && (<Badge color="#DF2E49" count={"Annulé"} />)}
                {(record.status === "Terminé") && (<Badge color="#00C875" count={"Terminé"} />)}
            </div>,
        }
    ];


    const onSelect = (value) => {
        setSelectedMonth(value);
        setCurrentMonth(monthList[value.format('M')] + " " + value.format('YYYY'));
    };


    const getListData = (value) => {
        const dateStr = value.format('YYYY-MM-DD');
        return events[dateStr] || [];
    };

    const dateCellRender = (value) => {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData.map(item => (
                    <li key={item.key}>
                        <div className="line" key={value.month() + "_" + value.date()}></div>
                    </li>
                ))}
            </ul>
        );
    };

    const cellRender = (current, info) => {
        if (info.type === 'date') return dateCellRender(current);
        return info.originNode;
    };


    const items = [
        /*{
            label:
                <div className="sans-pro-regular color-5B6E8C ">
                    <Space><ReloadOutlined style={{fontSize:"18px", verticalAlign:"top", marginTop:"1px"}} />  <span className="text">Automatiser le planning sur un logement</span></Space>
                </div>,
            key: '1',
        },*/
        {
            label: <div onClick={() => setShowModalProgrammerService(true)} className="sans-pro-regular color-5B6E8C">
                <Space><EditOutlined style={{ fontSize: "18px", verticalAlign: "top", marginTop: "1px" }} />  <span className="text">Programmer un service manuellement</span></Space>
            </div>,
            key: '2',
        },
        {
            label: <div className="sans-pro-regular color-5B6E8C" onClick={() => handleCreate()}>
                <Space><PlusCircleOutlined style={{ fontSize: "18px", verticalAlign: "top", marginTop: "1px" }} />  <span className="text">Créer un nouveau logement & programmer</span></Space>
            </div>,
            key: '3',
        },
        {
            label: <div className="sans-pro-regular color-5B6E8C disable" onClick={() => null}>
                <Space><IoCalendarClear style={{ fontSize: "18px", verticalAlign: "top", marginTop: "1px" }} />  <span className="text">Booster votre taux d'occupation</span></Space>
            </div>,
            key: '4',
        },

    ];

    const customHeader = () => {
        return (
            <>
                <div className="monthhead">
                    <div className="left" onClick={goToPreviousMonth}><CaretLeftOutlined /></div>
                    <div className="month">{currentMonth}</div>
                    <div className="right" onClick={goToNextMonth}><CaretRightOutlined /></div>
                </div>
                <div className="custom-weekdays">
                    <div>Dim</div>
                    <div>Lun</div>
                    <div>Mar</div>
                    <div>Mer</div>
                    <div>Jeu</div>
                    <div>Ven</div>
                    <div>Sam</div>
                </div>
            </>
        );
    };

    const goToNextMonth = async () => {
        await setSelectedMonth(selectedMonth.add(1, 'month')); // Ajouter un mois à la date actuelle
        await setStartDate(selectedMonth.year() + "-" + (selectedMonth.subtract(2, 'month').month() === 0 ? 1 : selectedMonth.subtract(2, 'month').month()).toString().padStart(2, '0') + "-01");
        let tmpDate = selectedMonth.add(4, 'month');
        await setEndDate(tmpDate.year() + "-" + (tmpDate.month() === 0 ? 1 : tmpDate.month()).toString().padStart(2, '0') + "-01");
    };

    // Fonction pour changer au mois précédent
    const goToPreviousMonth = async () => {
        await setSelectedMonth(selectedMonth.subtract(1, 'month')); // Soustraire un mois à la date actuelle
        await setStartDate(selectedMonth.year() + "-" + (selectedMonth.subtract(2, 'month').month() === 0 ? 1 : selectedMonth.subtract(2, 'month').month()).toString().padStart(2, '0') + "-01");
        let tmpDate = selectedMonth.add(3, 'month');
        await setEndDate(tmpDate.year() + "-" + (tmpDate.month() === 0 ? 1 : tmpDate.month()).toString().padStart(2, '0') + "-01");
    };


    // FILTERS
    const handleFilterStatus = (value) => {
        handleFetchPrestations({ status: [value] })
    };

    const handleFilterResponsable = (value) => {
        if (value === "Tout") {
            handleFetchPrestations({ "start_date": startDate, "end_date": endDate })
        } else {
            handleFetchPrestations({ intervenants: [value] })
        }
    };


    const handleFilterServices = (value) => {
        if (value === "Tout") {
            handleFetchPrestations({ "start_date": startDate, "end_date": endDate })
        } else {
            handleFetchPrestations({ services: [value] })
        }
    };

    return (
        <div className='planning-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Planning de ménage</h1>
                </div>
            </div>

            <div>
                <Row>
                    <Col lg={8}>
                        <Input
                            className='search-input'
                            placeholder="Chercher par nom de logement..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                        />
                    </Col>
                </Row>
                <br />
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Statut:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleFilterStatus}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'Programmé',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/a-faire-icon.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">A faire</div>
                                        </div>,
                                    },
                                    {
                                        value: 'En cours',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/Play-Icon-black.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                En cours
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'Terminé',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/done-icon-black.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                Terminée
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'Annulé',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="icone">
                                                <img src="./assets/img/Presta-annulee-icone.png" alt="icone" />
                                            </div>
                                            <div className="text color-213856">
                                                Annulée
                                            </div>
                                        </div>,
                                    },
                                ]}
                                popupClassName="status-classe-select-planning-login"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleFilterResponsable}
                                suffixIcon={
                                    <div className="icon-logement-select">
                                        <img src="../assets/img/arrow-down-gray.png" alt="dropdown arrow" />
                                    </div>
                                }
                                options={[
                                    {
                                        value: 'Tout', label:
                                            <div className="element-filter-tab">
                                                <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                                <div className="text color-213856">
                                                    Tout
                                                </div>
                                            </div>,
                                    },
                                    ...aidemenageres.map((item) => ({
                                        value: item.id,
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />

                                            <div className="text color-213856">
                                                {item?.firstname} {item?.lastname}
                                            </div>
                                        </div>,
                                    })),
                                ]}
                                popupClassName="status-classe-select-responsable"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Services:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleFilterServices}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'Tout',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="text color-213856">Tout</div>
                                        </div>,
                                    },
                                    {
                                        value: 'Ménage classique',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="text color-213856">Ménage classique</div>
                                        </div>,
                                    },
                                    {
                                        value: 'Ménage en profondeur',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="text color-213856">Ménage en profondeur</div>
                                        </div>,
                                    },
                                ]}
                                popupClassName="status-classe-select-ville"
                            />
                        </div>
                    </div>
                    <div className='blc-right'>
                        <Dropdown.Button
                            className="cree-planning"
                            overlayClassName="planning-add-dorpdown"
                            icon={<div className='icon-logement-select'><img src="../assets/img/arrow-down.png" alt="add" /> </div>}
                            menu={{ items }}>
                            <div onClick={() => setShowModalProgrammerService(true)}>
                                <IoAdd className='icon' />
                                Programmer un ménage
                            </div>
                        </Dropdown.Button>
                    </div>
                </div>
                <br />
                <Row>
                    <Col lg={14} className="calendar-zone">
                        {/* {prestations.length > 0 ?
                            <Calendar value={selectedMonth} onPanelChange={onPanelChange} onSelect={onSelect} cellRender={cellRender} headerRender={customHeader} className="planning-calendar" />
                            : ""} */}
                        <Spin spinning={isLoading}>
                            <Calendar value={selectedMonth} onPanelChange={onPanelChange} onSelect={onSelect} cellRender={cellRender} headerRender={customHeader} className="planning-calendar" />
                        </Spin>
                    </Col>

                    <Col lg={10} className="apercu-zone">
                        <div className="content-zone">
                            <div className="title sans-pro-semi-bold">Prestation du jour</div>
                            <Table
                                className="planning-table"
                                dataSource={selectedTable}
                                columns={columns}
                                locale={{
                                    emptyText: (
                                        <Row>
                                            <Col lg={6}></Col>
                                            <Col lg={12}>
                                                <div className="empty-Tab-planning-prestation">
                                                    <img src="/assets/img/empty-tab.png" alt="airbnb" className="plateforme-img" />
                                                    <div className="title">Aucune prestation prévue ce jour </div>
                                                </div>
                                            </Col>
                                            <Col lg={6}></Col>
                                        </Row>
                                    ),
                                }}
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            <ProgrammerManuellementModalView
                status={showModalProgrammerService}
                setValidProgram={() => setShowModalProgrammerValid(true)}
                setPrestationId={setPrestationId}
                handleCancel={() => setShowModalProgrammerService(false)}
            />
            {isSuccess && <PlanningSuccess onOpenDetail={setShowModalProgrammeApercu} onOpenNewPrestation={setShowModalProgrammerService} comeFrom={"planning"} />}
            <PlanningDetail
                prestationId={prestationId}
                status={showModalProgrammeApercu}
                handleCancel={() => setShowModalProgrammeApercu(false)}
            />
            <LogementCreate
                status={showModal}
                logementId={logementId}
                setTab={"Adresse"}
                handleCancel={() => setShowModal(false)}
            />
        </div>

    );
}

export default PlanningPage;
