import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Row, Col, Table, Input, Select, Menu, Avatar, Popover, Progress, Button, Pagination } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FaArrowRightLong } from "react-icons/fa6";
import { IoAdd, IoEllipsisHorizontal } from "react-icons/io5";
import LogementCreate from './create';
import { useLogementCreateMutation, useLogementFetchOne2Mutation, useLogementWithPerformanceMutation } from './services/logement-api';
import { GoDotFill } from "react-icons/go";
import SupprimerLogementModalView from "./delete/supprimer";
import ImpossibleSupprimerLogementModalView from "./delete/imposible-suprimer";
import DemanderEquipeLogementModalView from "./modal/demander-equipe";
import { API_ROOT, convertDateStringToTimeStringComplete } from '../../utils/global-var';
import { setRefresh } from './services/logement-slice';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import ProgrammerManuellementModalView from "../planning/modal/programmer-manuellement";
import { renameLogement } from './utils/logement-utils';
import PlanningSuccess from '../planning/modal/planning-success';
import "moment/locale/fr";
import "./styles/style.scss"
import "./styles/styleMax1020.scss"
import 'react-circular-progressbar/dist/styles.css';
import MoyenPaiementModalView from '../modal-global/moyen-de-paiement';
import { useGetDefaultPaymentMethodQuery } from '../profil/services/profil-api';
import ArchiverLogementModalView from "./modal/archiver-modal";


function LogementPage() {
    const [showModal, setShowModal] = useState(false);
    const [tabDirectSet, setTabDirectSet] = useState("Adresse");
    const [showModalSupprimer, setShowModalSupprimer] = useState(false);
    const [showModalArchiver, setShowModalArchiver] = useState(false);
    const [showModalDemande, setShowModalDemande] = useState(false);
    const [showModalImpossibleSupprimer, setShowModalImpossibleSupprimer] = useState(false);
    const [showModalProgrammerService, setShowModalProgrammerService] = useState(false);
    const [showModalProgrammerValid, setShowModalProgrammerValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLogements, setIsLoadingLogements] = useState(false);
    const [logementId, setLogementId] = useState();
    const [logements, setLogements] = useState([]);
    const [logement, setLogement] = useState([]);
    const [responsables, setResponsables] = useState([]);
    const [planningStatus, setPlanningStatus] = useState([]);
    const [villes, setVilles] = useState([]);
    const [statusColors, setStatusColors] = useState([]);
    const [error, setError] = useState(false);
    const [logementCreate] = useLogementCreateMutation();
    const [logementWithPerformance] = useLogementWithPerformanceMutation();
    const [logementFetchOne2] = useLogementFetchOne2Mutation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRowRecords, setSelectedRowRecords] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const { isRefresh } = useSelector((state) => state.logement);
    const dispatch = useDispatch();
    const [resultSearch, setResultSearch] = useState([]);
    const [dbDataLength, setDbDataLength] = useState();
    const [totalPages, setTotalPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [showPayMethodModal, setPayMethodModal] = useState(false);
    const payMethod = useGetDefaultPaymentMethodQuery();

    const checkPayMethod = () => {
        if (payMethod.isSuccess && payMethod.data) {
            setShowModalDemande(true);
        } else {
            setPayMethodModal(true)
        }
    }

    const handleFetchLogement = async (logement_id_toFetch) => {
        logementFetchOne2({ id: logement_id_toFetch }).unwrap()
            .then((res) => { setLogement(res) })
            .catch(() => { });
    };

    //console.log(logements);
    //console.log(logement);
    const getMenuItems = (record) => [
        {
            label:
                <div className={"add-logement2-item sans-pro-regular " + ((record.status_color === "RED" || record.responsable_id !== null) && " disable ")} onClick={record.status_color === "RED" || record.responsable_id !== null ? null : () => { setSelectedRowKeys([record.logement_id]); checkPayMethod() }}>
                    <div className="imageSide">
                        <img src="../assets/img/equipe-add.png" alt="add" />
                    </div>
                    Demander une équipe de ménage
                </div>,
            key: '1itemsOption',
        },
        {
            label: <div className={"add-logement2-item sans-pro-regular " + ((record.status_color === "RED" || record.responsable_id === null) && " disable ")}>
                <div className="imageSide">
                    <img src="../assets/img/switch-team.png" alt="add" />
                </div>
                Remplacer l'équipe de ménage
            </div>,
            key: '2itemsOption',
        },
        {
            type: 'divider',
        },
        {
            label: <div className={"add-logement2-item sans-pro-regular " + ((record.status_color === "RED" || record.responsable_id === null) && " disable ")} onClick={record.status_color === "RED" || record.responsable_id === null ? null : () => {
                handleFetchLogement(record.logement_id).then(r => setShowModalProgrammerService(true));
            }}>
                <div className="imageSide reduction-taille">
                    <img src="../assets/img/presta-icon.png" alt="add" />
                </div>
                Programmer une prestation
            </div>,
            key: '3itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular" onClick={() => {
                setLogementId(record.logement_id);
                setTabDirectSet("Automatisation");
                setShowModal(true);
            }}>
                <div className="imageSide reduction-taille">
                    <img src="../assets/img/synchro-butt.png" alt="add" />
                </div>
                Automatiser le planning
            </div>,
            key: '4itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular" onClick={() => { handleDuplicate(record.logement_id) }}>
                <div className="imageSide">
                    <img src="../assets/img/duplicate-icon.png" alt="add" />
                </div>
                Dupliquer
            </div>,
            key: '5itemsOption',
        },
        {
            label: <div className="add-logement2-item sans-pro-regular" onClick={() => { setLogementId(record.logement_id); setShowModalArchiver(true); }}>
                <div className="imageSide">
                    <img src="../assets/img/archiver.png" alt="add" />
                </div>
                Archiver
            </div>,
            key: '8itemsOption',
        },
        {
            type: 'divider',
        },
        {
            label:
                <div className={"add-logement2-item sans-pro-regular " + (record.status_color === "GREEN" && record.next_creneau_intervention_debut !== null ? " disable disable-block" : "")} style={{ color: "red" }} onClick={() => {
                    if (record.status_color === "GREEN" && record.next_creneau_intervention_debut !== null) {
                    } else {
                        setLogementId(record.logement_id); setShowModalSupprimer(true);
                    }
                }
                }>
                    <div className="imageSide">
                        {record.status_color === "GREEN" && record.next_creneau_intervention_debut !== null ? <img src="../assets/img/trash-dosabled.png" alt="add" /> : <img src="../assets/img/trash.png" alt="add" />}
                    </div>
                    Supprimer le logement
                </div>,
            key: '6itemsOption',
        },
    ];

    const customStatus = (status) => {
        switch (status) {
            case "ORANGE":
                return "#F5C242";
            case "GREEN":
                return "#65ae04";
            case "RED":
                return "#ff0000e3";
            default:
                return "gray";
        }
    };

    const contentInfo = (
        <div className="infoLogement">
            <p>
                Code couleur : <br />
                Rond vert = Logement complété à 100% et <br /> équipe de ménage en place <br />
                Rond orange = Logement complété à 100% <br /> sans équipe de ménage <br />
                Rond rouge = Logement à compléter (tous <br /> les champs obligatoires ne sont pas remplis
            </p>
        </div>
    );

    const contentInfo2 = (
        <div className="infoLogement">
            <p>
                Ce paramètre indique le taux de <br />
                réussite des prestations sur le <br />
                logement, soit le pourcentage de <br />
                prestations qui ont reçu une note de 4 <br />
                ou 5 étoiles sur l’ensemble des <br />
                prestations effectuées.
            </p>
        </div>
    );

    const columns = [
        {
            title: "Nom du logement",
            width: "20%",
            render: (record) => (
                <div onClick={() => { setLogementId(record.logement_id); setShowModal(true); setTabDirectSet("Adresse"); }} className="logement-libelle-table" >
                    <span>{record.logement_libelle} </span> &nbsp;
                </div>
            ),
        },

        {
            title: '',
            render: (record) => (
                <div>{
                    record.logement_gestion_cle === "Jeu de clés confié à l’aide-ménagère" && <img src="./assets/img/icon-keys.png" className="icon-sidebar" style={{ width: "14px", marginRight: "" }} />}
                </div>
            ),
        },

        {
            title: <div className="statusHeadColClass">
                Statut
                <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                    <div className="info-logement">
                        <img src="/assets/img/info-circled.png" alt="info" />
                    </div>
                </Popover>
            </div>,
            render: (record) => (
                <div>
                    <GoDotFill className='icon' style={{ color: `${customStatus(record.status_color)}`, fontSize: "25" }} />
                    {record.icalendar_url_number !== "0" ? <img src="../assets/img/recycle.png" alt="add" style={{ width: "16px", marginLeft: "5px" }} /> : ""}
                </div>
            ),
        },

        {
            title: 'Responsable de site',
            render: (record) => (
                <Popover placement="top" content={
                    <div style={{ margin: "15px" }}>
                        <span>{record.responsable_firstname} {record.responsable_lastname}</span><br />
                        <span>{record.responsable_phone}</span>
                    </div>} overlayClassName="infoContentLogement">
                    {record?.assignment_status === "Offre envoyée" && <div className="offre-envoyee"><div className="texte">Offre envoyée</div><Avatar src={`${API_ROOT}/${record.responsable_avatar}`} /></div>}
                    {record?.assignment_status === "Assignation en cours" && <div className="assignation-en-cour"><div className="texte">Assignation en cours</div><Avatar src={`${API_ROOT}/${record.responsable_avatar}`} /></div>}
                    {record?.assignment_status === "Offre refusée" && <Avatar src={`${API_ROOT}/${record.first_responsable_avatar}`} />}
                    {record?.assignment_status === "Offre acceptée" && <Avatar src={`${API_ROOT}/${record.responsable_avatar}`} />}
                    {/*<Avatar src={`${API_ROOT}/${record.responsable_avatar}`}/>*/}
                </Popover>
            ),
        },
        {
            title: 'Prochaine prestation',
            render: (record) => (
                <div>
                    {record.next_creneau_intervention_debut === null ? "" : <span>{convertDateStringToTimeStringComplete(record.next_creneau_intervention_debut)}</span>}
                </div>
            ),
        },
        {
            title: 'Forfait ménage',
            render: (record) => (
                <div>{(record.forfait_menage * (user?.profil?.toLowerCase() === "particulier" ? 1.1 : 1)).toFixed(2)} €</div>
            ),
        },
        {
            title: 'Promo',
            width: "7%",
            render: (record) => (
                <div>
                    {record.on_promotion === 1 ? <div className='tarif-reduit'>Tarif reduit</div> : ""}
                </div>
            ),
        },
        {
            title: <div className="statusHeadColClass">
                Perf
                <Popover placement="top" content={contentInfo2} overlayClassName="infoContentLogement">
                    <div className="info-logement">
                        <img src="/assets/img/info-circled.png" alt="info" />
                    </div>
                </Popover>
            </div>,
            render: (record) => (
                <div>
                    <Progress type="circle" percent={parseFloat(record.performance_percentage ? record.performance_percentage : 0).toFixed(0)} format={percent => `${percent} % des prestations se sont parfaitement déroulées`} size={20} />
                </div>
            ),
        },
        {
            title: "Créé le",
            key: "createdAt",
            render: (record) => (
                <span> {convertDateStringToTimeStringComplete(record.logement_createdAt)}</span>
            ),
        },
        {
            title: "",
            key: "option",
            width: 5,
            render: (record) => (
                <div className="tab-option">
                    <Dropdown
                        overlay={<Menu items={getMenuItems(record)} />}
                        placement="bottomRight" trigger={['click']}>
                        <IoEllipsisHorizontal style={{ fontSize: "19px", marginRight: "10px" }} />
                    </Dropdown>
                </div>
            ),
        },
    ];


    const onSelectChange = (newSelectedRowKeys, record) => {
        setSelectedRowKeys(newSelectedRowKeys);
        setSelectedRowRecords(record);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const items = [
        {
            label: <div className="add-logement-item add-logement-item-1 sans-pro-regular" onClick={() => handleCreate()}>
                <div className="imageSide">
                    <img src="../assets/img/ajouter-logement.png" alt="add" />
                </div>
                Ajouter un logement
            </div>,
            key: '1items',
        },
        {
            label: <div className="add-logement-item add-logement-item-2 disable">
                <div className="imageSide">
                    <img src="../assets/img/importer-masse.png" alt="add" />
                </div>
                Importer en masse
            </div>,
            key: '2items',
        },
    ];


    const handleFetchLogements = async (data) => {
        setIsLoadingLogements(true)
        logementWithPerformance(data).unwrap()
            .then((res) => {
                setDbDataLength(res.data.pages.dbDataLength)
                setTotalPages(res.data.pages.totalPages)
                setResponsables(res.filters.responsables)
                setStatusColors(res.filters.status_color)
                setPlanningStatus(res.filters.planning_status)
                setVilles(res.filters.villes)
                dispatch(setRefresh(false));
                setLogements(renameLogement(res.data.logements))
                setIsLoadingLogements(false)
            })
            .catch(() => {
                setIsLoadingLogements(false)
            });
    };


    useEffect(() => {
        const data = ({ page: pageNumber, pageSize: 10, order: "DESC" })
        handleFetchLogements(data)
    }, [pageNumber]);


    // CREATE
    const handleCreate = async () => {
        const data = ({ libelle: "Logement de " + user.firstname, user: user.id })
        setIsLoading(true);
        logementCreate(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setShowModal(true)
                setLogementId(res.id);
                dispatch(setRefresh(true));
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }

    useEffect(() => {
        isRefresh &&
            handleFetchLogements({ page: 1, pageSize: 10, order: "DESC" })
    }, [isRefresh]);


    // DUPLICATE
    const handleDuplicate = async (id) => {
        setIsLoadingLogements(true);
        logementFetchOne2({ id: id })
            .unwrap()
            .then((res) => {
                const { id, cart, assignment, cout_duree, demande_specifique, icalendarUrl, instructions, kit, prestation, user, createdAt, updtedAt, ...newData } = res;
                const data = { ...newData, user: user.id }
                logementCreate(data)
                    .unwrap()
                    .then(() => {
                        dispatch(setRefresh(true));
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        setError(error.data.message);
                    });
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }

    const handleDuplicateMultiple = async () => {
        setIsLoadingLogements(true);
        const duplicatePromises = [];
        for (const id of selectedRowKeys) {
            const duplicatePromise = logementFetchOne2({ id: id })
                .unwrap()
                .then((res) => {
                    const { id, cart, assignment, cout_duree, demande_specifique, icalendarUrl, instructions, kit, prestation, user, createdAt, updatedAt, ...newData } = res;
                    const data = { ...newData, user: user.id };
                    return logementCreate(data).unwrap();
                })
                .catch((error) => {
                    console.error(`Failed to duplicate logement with ID ${id}`);
                    throw error;
                });

            duplicatePromises.push(duplicatePromise);
        }

        Promise.all(duplicatePromises)
            .then(() => {
                dispatch(setRefresh(true));
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    }


    // SEARCH
    const handleSearch = (event) => {
        const query = event.target.value;
        setIsLoadingLogements(true)
        handleFetchLogements({ search: query })
    };

    // FILTERS
    const handleFilterStatusColor = (value) => {
        handleFetchLogements({ status_color: [value] })
    };

    const handleFilterResponsable = (value) => {
        if (value === "Tout") {
            handleFetchLogements({ page: 1, pageSize: 10, order: "DESC" })
        } else {
            handleFetchLogements({ responsables: [value] })
        }
    };

    const handleFilterVilles = (value) => {
        if (value === "Tout") {
            handleFetchLogements({ page: 1, pageSize: 10, order: "DESC" })
        } else {
            handleFetchLogements({ villes: [value] })
        }
    };


    let locale = {
        emptyText: (
            <div className='empty-blc'>
                <div className='blc'>
                    <div className='home-icon'>
                        <img src='assets/img/icon-logement.png' />
                    </div>
                    <h4>Créez votre premier logement</h4>
                    <p>Ajoutez un logement et complétez le cahier des charges pour estimer les frais de ménage.</p>
                    <a href='https://help.kliner.com/fr/articles/9348501' target='_blank' className="en-savoir-plus">En savoir plus <FaArrowRightLong className='icon' /></a>
                    <Button type="primary" className='ant-btn-icon' onClick={() => handleCreate()} loading={isLoading}><IoAdd className='icon' /> Ajouter un logement</Button>
                </div>
            </div>
        )
    };

    const checkNotDemandePossible = (listRow) => {
        for (let i = 0; i < listRow.length; i++) {
            if (listRow[i].status_color === "RED") {
                return false;
                break;
            }
        }
        return true;
    }


    const onChange = (value) => {
        setPageNumber(value);
    };

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a><LeftOutlined /></a>;
        }
        if (type === 'next') {
            return <a><RightOutlined /></a>;
        }
        return originalElement;
    };


    //console.log(logements);
    return (
        <div className='logement-page'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Logements</h1>
                </div>
            </div>
            {hasSelected && <div className="selectBar">
                <div className="contentBar">
                    <div className="selection sans-pro-regular"> {selectedRowKeys?.length} selectionnés</div>
                    <div className="divider"></div>
                    <div className={"demande-equipe sans-pro-regular " + (checkNotDemandePossible(selectedRowRecords) ? "" : " disable-selectBar")} onClick={checkNotDemandePossible(selectedRowRecords) ? () => checkPayMethod() : null}> <div className="image"><img src="../assets/img/equipe-icon-w.png" alt="add" /></div>    Demander une équipe de ménage</div>
                    <div className="divider"></div>
                    <div className={"demande-equipe sans-pro-regular "} onClick={() => handleDuplicateMultiple()} > <div className="image"> <img src="../assets/img/duplicate-icon-w.png" alt="add" /></div>    Dupliquer</div>
                    <div className="divider"></div>
                    <div className={"demande-equipe sans-pro-regular "} onClick={() => setShowModalArchiver(true)} > <div className="image"> <img src="../assets/img/archiver-white.png" alt="add" /></div>    Archiver</div>
                    <div className="divider"></div>
                    <div className="demande-equipe sans-pro-regular" onClick={() => setShowModalSupprimer(true)}> <div className="image"><img src="../assets/img/trash-w.png" alt="add" /></div>    Supprimer</div>
                </div>
            </div>}

            <div>
                <Row>
                    <Col lg={8}>
                        <Input
                            className='search-input'
                            placeholder="Chercher par nom de logement..."
                            prefix={<SearchOutlined className="site-form-item-icon" />}
                            onChange={handleSearch}
                        />
                    </Col>
                </Row>
                <br />
                <div className='flex justify-content-space-between'>
                    <div className='blc-left'>
                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Statut:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleFilterStatusColor}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'ALL',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="text color-213856">
                                                Tout
                                            </div>
                                        </div>,
                                    },

                                    {
                                        value: 'GREEN',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="text color-213856">
                                                Actif
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'ORANGE',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                            <div className="text color-213856">
                                                Prêt
                                            </div>
                                        </div>,
                                    },
                                    {
                                        value: 'RED',
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />

                                            <div className="text color-213856">
                                                A compléter
                                            </div>
                                        </div>,
                                    },
                                ]}
                                popupClassName="status-classe-select-login"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Responsable:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleFilterResponsable}
                                suffixIcon={
                                    <div className="icon-logement-select">
                                        <img src="../assets/img/arrow-down-gray.png" alt="dropdown arrow" />
                                    </div>
                                }
                                options={[
                                    {
                                        value: 'Tout', label:
                                            <div className="element-filter-tab">
                                                <input type="checkbox" name="afaire" className="checkBoxFilter" />
                                                <div className="text color-213856">
                                                    Tout
                                                </div>
                                            </div>,
                                    },
                                    ...responsables.map((responsable) => ({
                                        value: responsable.responsable_id,
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />

                                            <div className="text color-213856">
                                                {responsable.responsable_firstname} {responsable.responsable_lastname}
                                            </div>
                                        </div>,
                                    })),
                                ]}
                                popupClassName="status-classe-select-responsable"
                            />
                        </div>

                        <div className='blc-filter'>
                            <span className="sans-pro-light color-5B6E8C">Ville:</span>
                            <Select
                                defaultValue="Tout"
                                onChange={handleFilterVilles}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                                options={[
                                    {
                                        value: 'Tout', label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />

                                            <div className="text color-213856">
                                                Tout
                                            </div>
                                        </div>
                                    },
                                    ...villes.map((item) => ({
                                        value: item.logement_ville,
                                        label: <div className="element-filter-tab">
                                            <input type="checkbox" name="afaire" className="checkBoxFilter" />

                                            <div className="text color-213856">{item.logement_ville} </div>
                                        </div>,
                                    })),
                                ]}
                                popupClassName="status-classe-select-ville"
                            />
                        </div>
                    </div>
                    <div className='blc-right'>
                        <Dropdown.Button
                            className="cree-logement"
                            overlayClassName="logement-add-drompdown"
                            icon={<div className='icon-logement-add'><img src="../assets/img/arrow-down.png" alt="add" /></div>} menu={{ items }} onClick={() => handleCreate()} loading={isLoading}>
                            <div className='icon-logement-add'>
                                <img src="../assets/img/add-logement2.png" alt="add" />
                            </div>
                            Créer un logement
                        </Dropdown.Button>
                    </div>
                </div>
                <br />
                <Table
                    className="logement-table"
                    pagination={false}
                    rowSelection={rowSelection}
                    dataSource={logements}
                    columns={columns}
                    loading={isLoadingLogements}
                    rowKey={(record) => record.logement_id}
                    locale={locale}
                />
                <br />
                <Pagination defaultCurrent={1} total={dbDataLength} onChange={onChange} itemRender={itemRender} />
            </div>


            <LogementCreate
                status={showModal}
                logementId={logementId}
                setTab={tabDirectSet}
                handleCancel={() => setShowModal(false)}
            />
            <SupprimerLogementModalView
                logementId={logementId}
                logementIds={selectedRowKeys}
                status={showModalSupprimer}
                nbLogement={selectedRowKeys?.length}
                handleShowNotPossibleModal={setShowModalImpossibleSupprimer}
                handleCancel={() => setShowModalSupprimer(false)}
            />
            <ArchiverLogementModalView
                logementId={logementId}
                logementIds={selectedRowKeys}
                status={showModalArchiver}
                nbLogement={selectedRowKeys?.length}
                handleCancel={() => setShowModalArchiver(false)}
            />
            <ImpossibleSupprimerLogementModalView
                status={showModalImpossibleSupprimer}
                nbLogement={selectedRowKeys?.length}
                handleCancel={() => setShowModalImpossibleSupprimer(false)}
            />
            <DemanderEquipeLogementModalView
                logementIds={selectedRowKeys}
                status={showModalDemande}
                nbLogement={selectedRowKeys?.length}
                handleCancel={() => setShowModalDemande(false)}
            />
            <ProgrammerManuellementModalView
                logementParams={logement}
                prestationsNb={logement?.prestation?.length}
                status={showModalProgrammerService}
                setValidProgram={() => setShowModalProgrammerValid(true)}
                handleCancel={() => setShowModalProgrammerService(false)}
            />
            <PlanningSuccess
                status={showModalProgrammerValid}
                handleCancel={() => setShowModalProgrammerValid(false)}
            />

            <MoyenPaiementModalView
                status={showPayMethodModal}
                handleCancel={() => setPayMethodModal(false)} />
        </div>
    );
}

export default LogementPage;
