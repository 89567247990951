import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Button, Col, Modal, Progress, Row, Spin, Popover, Dropdown, Menu } from "antd";
import {
    BranchesOutlined,
    CalendarOutlined,
    CloseCircleFilled,
    EyeFilled,
    FileTextOutlined,
    HistoryOutlined
} from "@ant-design/icons";
import { IoArrowForwardCircleOutline, IoCartOutline, IoDocumentTextOutline } from "react-icons/io5";
import {
    API_ROOT,
    convertDateStringToTimeString,
    monthList,
    coutHorsTax,
    coutTTC, convertDateStringToTimeStringDateOnly, handleConvert, convertDateStringToTimeStringComplete,
} from "../../utils/global-var";
import dayjs from "dayjs";
import KitDeLingeModalView from "./modal/modal/apercu_planning/kits-de-linge";
import KitAccueilModalView from "./modal/modal/apercu_planning/kit-acceuil";
import ControleQualiteModalView from "./modal/modal/apercu_planning/controle-qualité";
import EvaluationModalView from "./modal/modal/apercu_planning/evaluation";
import { usePrestationFetchOneMutation } from "./services/planning-api";
import { useCartFetchAmenitiesQuery } from "../logements/services/logement-api";
import moment from "moment";
import UpdateTime from "./update/update-time";
import { setRefresh } from "./services/planning-slice";
import "moment/locale/fr";
import "./styles/style.scss"
import PrestationFordward from "./update/prestation-forward";
import PrestationDelete from "./update/prestation-cancel";
import { useLogFetchQuery } from "./services/log-api";
import { useGetDefaultPaymentMethodQuery } from "../profil/services/profil-api";
import ControleFinPrestationPage from "./modal/controle_fin_prestation";
import InstructionSpecialeModalView from "./modal/instruction_special";
import ModifierKitsModalView from "./modal/modifier_kit";
import ModifierLitsModalView from "./modal/modifier_lit";
import { useAminitieFetchQuery, useLingeFetchQuery } from "./services/aminitie-linge-api";
import AjusterPrixPrestationModalView from "./modal/ajustement-page";


const PlanningDetail = ({ prestationId, status, handleCancel }) => {
    const [showModalCreneau, setShowModalCreneau] = useState(false);
    const [showModalKit, setShowModalKit] = useState(false);
    const [showModalAccueil, setShowModalAccueil] = useState(false);
    const [showModalControle, setShowModalControle] = useState(false);
    const [showModalInstructionSpecial, setShowModalInstructionSpecial] = useState(false);
    const [prestation, setPrestation] = useState();
    const [showModalEvaluation, setShowModalEvaluation] = useState(false);
    const [showModalModifierKit, setShowModalModifierKit] = useState(false);
    const [showModalModifierLit, setShowModalModifierLit] = useState(false);
    const [showModalModifierPrix, setShowModalModifierPrix] = useState(false);
    const defaultPaymentMethod = useGetDefaultPaymentMethodQuery();
    const [currentMonth, setCurrentMonth] = useState(monthList[parseInt(new Date().getMonth() + 1)] + " " + (new Date().getFullYear()));
    const [selectedMonth, setSelectedMonth] = useState(dayjs());
    const [prestationFetchOne] = usePrestationFetchOneMutation();
    const [petitDej, setPetitDej] = useState(0);
    const [entretien, setEntretien] = useState(0);
    const [hygiene, setHygiene] = useState(0);
    const [hygienemini, setHygienemini] = useState(0);
    const [entretienmini, setEntretienmini] = useState(0);
    const [petitDejmini, setPetitDejmini] = useState(0);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const logList = useLogFetchQuery({ service: "prestation", page: 1, pagesize: 6, order: "DESC", item: prestationId });
    const cart = useCartFetchAmenitiesQuery(prestation?.logement?.id)
    const { isRefresh } = useSelector((state) => state.planning);
    const { user } = useSelector((state) => state.auth);
    const [parentVisible, setParentVisible] = useState(false);
    const [showControleSinPrestation, setShowControleSinPrestation] = useState(false);
    const [datePast, setDatePast] = useState(false);
    const amenitieList = useAminitieFetchQuery({ id: prestationId });
    const lingeList = useLingeFetchQuery({ id: prestationId });
    //console.log("lingeList");
    //console.log(lingeList);

    useEffect(() => {
        handleFetchPrestation(prestationId);
    }, [prestationId, status, showModalModifierKit, showModalModifierLit, setShowModalEvaluation]);

    //console.log(logList?.data);

    useEffect(() => {
        isRefresh &&
            handleFetchPrestation(prestationId);
    }, [isRefresh]);

    useEffect(() => {
        if (!parentVisible) {
            setClickedForward(false);
            setClickedCancel(false);
        }
    }, [parentVisible]);

    useEffect(() => {
        if (prestation?.logement?.length !== 0 && prestation?.logement !== null) {
            setPetitDej(0);
            setEntretien(0);
            setHygiene(0);
            setEntretienmini(0);
            setHygienemini(0);
            setPetitDejmini(0);
            amenitieList?.data?.map((item, index) => {
                if (item.produit.produit.trim() === "Kit Petit déjeuner") {
                    setPetitDej(item.quantite);
                }
            });
            amenitieList?.data?.map((item, index) => {
                if (item.produit.produit.trim() === "Kit Entretien") {
                    setEntretien(item.quantite);
                }
            });
            amenitieList?.data?.map((item, index) => {
                if (item.produit.produit.trim() === "Kit Hygiène") {
                    setHygiene(item.quantite);
                }
            });
            amenitieList?.data?.map((item, index) => {
                if (item.produit.produit.trim() === "Mini Kit Petit déjeuner") {
                    setPetitDejmini(item.quantite);
                }
            });
            amenitieList?.data?.map((item, index) => {
                if (item.produit.produit.trim() === "Mini Kit Entretien") {
                    setEntretienmini(item.quantite);
                }
            });
            amenitieList?.data?.map((item, index) => {
                if (item.produit.produit.trim() === "Mini Kit Hygiène") {
                    setHygienemini(item.quantite);
                }
            });
        }
        setDatePast(false);
        if (prestation?.quality_control?.customer_stars_date !== null) {
            const dateString = prestation?.quality_control?.customer_stars_date ?? prestation?.endDate;
            const targetDate = new Date(dateString);
            const currentDate = new Date();
            const dateAfter7Days = new Date();
            dateAfter7Days.setDate(currentDate.getDate() + 14);
            if (targetDate <= dateAfter7Days) {
                setDatePast(false);
            } else {
                setDatePast(true);
            }
        }
    }, [prestation?.logement, prestation, isRefresh]);

    const address = (user) => {
        let data = user?.residence_entreprise || user?.residence_principale || "{}";
        return JSON.parse(data);
    }


    const handleFetchPrestation = async (prestationId) => {
        setLoading(true)
        prestationFetchOne({ id: prestationId })
            .unwrap()
            .then((res) => {
                setPrestation(res);
                setLoading(false);
                dispatch(setRefresh(false));
            })
            .catch(() => {
                setLoading(false);
            });
    }


    const customStatus = (value) => {
        switch (value) {
            case "En cours":
                return (<Badge color="#FDAC3D" count={"En cours"} />);
            case "Programmé":
                return (<Badge color="#333333" count={"À faire"} />);
            case "Annulé":
                return (<Badge color="#DF2E49" count={"Annulé"} />);
            case "Terminé":
                return (<Badge color="#00C875" count={"Terminé"} />);
            default:
                return (<Badge color="#333333" count={"À faire"} />);
        }
    }


    const kitCount = (items, product) => {
        let total = 0;
        for (let item of items) {
            if (item.produit.produit === product) {
                total += item.quantite;
            }
        }
        return total;
    }

    const [clickedForward, setClickedForward] = useState(false);
    const [clickedForwardCancel, setClickedCancel] = useState(false);

    const handleClickForward = (open) => {
        // console.log(open);
        setClickedForward(open);
    };


    const handleClickCancel = (open) => {
        setClickedCancel(open);
    };


    const getPaymentMethodIcon = (paymentMethod) => {
        if (paymentMethod?.type === "card") {
            switch (paymentMethod?.card?.brand) {
                case "visa": return "../assets/img/visa.png"
                case "mastercard": return "../assets/img/mastercard.png"
                case "amex": return "../assets/img/amex.png"
                default: return "../assets/img/empty-payment-icon.png";
            }
        } else if (paymentMethod?.type === "sepa_debit") {
            return "../assets/img/sepa-icon.png";
        } else {
            return "../assets/img/empty-payment-icon.png";
        }
    }


    const getLast4 = (paymentMethod) => {
        switch (paymentMethod?.type) {
            case "card":
                return paymentMethod?.card.last4;
            case "sepa_debit":
                return paymentMethod?.sepa_debit.last4;
            default:
                return "";
        }
    }

    const getPercent = () => {
        if (prestation?.quality_control === null) {
            return 0;
        }
        if (prestation?.quality_control?.bo_stars !== null) {
            return 100;
        }
        if (prestation?.quality_control?.prestation_finished_on_time) {
            return 80;
        }
        if (prestation?.quality_control?.prestation_started_on_time) {
            return 60;
        }
        if (prestation?.quality_control?.linge_amenities_deposes) {
            return 40;
        }
        if (prestation?.quality_control?.presence_confirmee) {
            return 20;
        }
        return 0;
    }

    const menuAcces = (
        <Menu>
            <Menu.Item key="cle">
                <strong>Instrucions d'accès</strong>
                <p>
                    {prestation?.data?.logement?.instruction_adresse ?? "Aucunes indications."}
                </p>
            </Menu.Item>
        </Menu>
    );

    const handleParentOpenChange = (open) => {
        setParentVisible(open);
    };

    //console.log(datePast);

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{ top: 50 }}
            closable={true}
            onCancel={() => handleCancel()}
            width={1400}
            className="apercu-programmer">

            <Spin spinning={loading}>
                <div className="programmer-a">
                    <div className="title sans-pro-regular">
                        <div className="indice">#{prestation?.id}</div>
                        <div className="title-label">Prestation du {convertDateStringToTimeStringDateOnly(prestation?.creneau_intervention_debut)}</div>
                        <div className="edit-icon">
                            <Popover
                                trigger="click"
                                destroyTooltipOnHide={true}
                                placement="bottom"
                                open={parentVisible}
                                onOpenChange={handleParentOpenChange}
                                content={(
                                    <div>
                                        <Popover
                                            open={clickedForward && parentVisible}
                                            onOpenChange={handleClickForward}
                                            trigger="click"
                                            placement="rightTop"
                                            destroyTooltipOnHide={true}
                                            title="Reporter la prestation"
                                            content={
                                                <PrestationFordward
                                                    handleCancel={() => setClickedForward(false)}
                                                    prestationId={prestation?.id}
                                                    creneauDebut={prestation?.creneau_intervention_debut}
                                                    creneauFin={prestation?.creneau_intervention_fin}
                                                />
                                            }>
                                            <div className="detail-presta-dropdown pointer" style={{ marginBottom: '10px' }}>
                                                <HistoryOutlined /> <span>Reporter</span>
                                            </div>
                                        </Popover>
                                        <Popover
                                            open={clickedForwardCancel && parentVisible}
                                            onOpenChange={handleClickCancel}
                                            trigger="click"
                                            placement="rightTop"
                                            destroyTooltipOnHide={true}
                                            content={
                                                <PrestationDelete
                                                    handleCancel={() => setClickedCancel(false)}
                                                    prestationId={prestation?.id} />
                                            }>
                                            <div className="detail-presta-dropdown pointer">
                                                <CloseCircleFilled style={{ color: "red" }} />  <span>Annuler</span>
                                            </div>
                                        </Popover>

                                    </div>
                                )}>
                                <div style={{ cursor: "pointer" }}>
                                    <img src="./assets/img/pen-colored.png" alt="pen" />
                                </div>
                            </Popover>
                        </div>
                        <div className="status">{customStatus(prestation?.status)}</div>
                    </div>

                    <Row>
                        <Col lg={16} className="content-box-padding-left">
                            <div className="content-box content-box-top">
                                <div className="mintitle sans-pro-regular">
                                    Détails de la commande
                                </div>
                                <Row>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoCartOutline /></div>
                                        </div>
                                        <div className="content">
                                            <div className="head sans-pro-semi-bold">Service</div>
                                            <div className="element sans-pro-regular">
                                                <span>Type:</span> {prestation?.service} {prestation?.serviceAppointRef === "laundry_collect" && <div style={{ display: "inline-block", marginLeft: "3px" }}> ({prestation?.serviceDescription})</div>}
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Durée:</span>
                                                {prestation?.logement?.type === "apartment" && prestation?.service === "Ménage classique" && handleConvert(prestation?.logement?.cout_duree?.duree_menage_classique)}
                                                {prestation?.logement?.type === "apartment" && prestation?.service === "Ménage en profondeur" && handleConvert(prestation?.logement?.cout_duree?.duree_menage_profondeur)}
                                                {prestation?.logement?.type === "apartment" && prestation?.service === "Service d'appoint" && "1h 0"}
                                                {prestation?.logement?.type === "villa" && "illimité"}
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Créneau:</span> Entre {convertDateStringToTimeString(prestation?.creneau_intervention_debut)} et {convertDateStringToTimeString(prestation?.creneau_intervention_fin, "end")}  <img src="./assets/img/pen-colored.png" onClick={() => setShowModalCreneau(true)} alt="pen" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoDocumentTextOutline /></div>
                                        </div>
                                        <div className="content">
                                            <div className="head sans-pro-semi-bold">Accès au logement
                                                {/*<div className="iconMore">
                                                    <img src="./assets/img/open-icon-colored.png" className="more"
                                                         alt="more"/>
                                                </div>*/}
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Adresse:</span> {prestation?.logement?.adresse}, {prestation?.logement?.ville}
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Guidage: </span>
                                                <Dropdown
                                                    overlay={menuAcces}
                                                    placement="top"
                                                    arrow
                                                >
                                                    <div className="button-info-supp" style={{ display: "inline-block" }}>
                                                        <a href="#">Voir les instruction d'accès</a>
                                                    </div>
                                                </Dropdown>
                                            </div>
                                            <div className="element sans-pro-regular">
                                                <span>Gestion des clés:</span> {prestation?.logement?.gestion_cle}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <br />
                                <Row>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoDocumentTextOutline /></div>
                                        </div>
                                        <div className="content">
                                            <div className="head sans-pro-semi-bold">Linge <img src="./assets/img/pen-colored.png" className="display-none" alt="pen" onClick={() => setShowModalKit(true)} /></div>
                                            {
                                                prestation?.logement?.laundryManagementType === "FULLY_MANAGED" &&
                                                <>
                                                    <div className="element sans-pro-regular"> <span>Fourni par:</span> Kliner</div>
                                                    <div className="element sans-pro-regular"><span>Linge simple:</span> {prestation?.details_info?.lit_simple ?? 0} Kit</div>
                                                    <div className="element sans-pro-regular">
                                                        <span>Linge double:</span> {parseInt(prestation?.details_info?.lit_double ?? 0) + parseInt(prestation?.details_info?.lit_king ?? 0)} Kits
                                                    </div>
                                                    <div className="element sans-pro-regular"><span>Blanchisserie:</span> Kliner</div>
                                                </>
                                            }
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="icon-content">
                                            <div className="icon"><IoDocumentTextOutline /></div>
                                        </div>
                                        {!cart.isFetching &&

                                            <div className="content">
                                                <div className="head sans-pro-semi-bold">Kit d'accueil <img src="./assets/img/pen-colored.png" className="display-none" onClick={() => setShowModalAccueil(true)} alt="pen" /></div>
                                                <Row gutter={24}>
                                                    <Col>
                                                        <div className="element sans-pro-regular">
                                                            <span>Kit Hygiène:</span> {kitCount(cart.data, "Kit Hygiène ")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Kit Entretien:</span>{kitCount(cart.data, "Kit Entretien")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Kit Petit Déjeuner:</span> {kitCount(cart.data, "Kit Petit déjeuner")}
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="element sans-pro-regular">
                                                            <span>Mini Kit Hygiène:</span> {kitCount(cart.data, "Mini Kit Hygiène")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Mini Kit Entretien:</span> {kitCount(cart.data, "Mini Kit Entretien")}
                                                        </div>
                                                        <div className="element sans-pro-regular">
                                                            <span>Mini Kit Petit déjeuner:</span> {kitCount(cart.data, "Mini Kit Petit déjeuner")}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="content-box content-box-top">
                                <div className="mintitle sans-pro-regular">
                                    Equipe d'entretien
                                </div>
                                <div className="content-responsable">
                                    <div className="user-icon">
                                        <img src={prestation?.responsable?.avatar ? `${API_ROOT}/${prestation?.responsable?.avatar}` : "./assets/img/logon.png"} alt="photo" />
                                    </div>
                                    <div className="user-info sans-pro-regular">
                                        <div className="name sans-pro-semi-bold">{prestation?.responsable?.firstname} {prestation?.responsable?.lastname}</div>
                                        <div className="status disable sans-pro-regular color-A7A7A7">{prestation?.responsable?.entreprise}</div>
                                        <div className="number disable sans-pro-regular color-A7A7A7">{prestation?.responsable?.phone}</div>
                                    </div>
                                    <div className="user-button">
                                        <Badge color={"violet"} className="sans-pro-regular" count={"Responsable"}></Badge>
                                    </div>
                                </div>
                                <div className="content-aide sans-pro-regular">
                                    <div className="user-icon">
                                        <img src={prestation?.aide_menagere?.avatar ? `${API_ROOT}/${prestation?.aide_menagere?.avatar}` : "./assets/img/user-empty.png"} alt="photo" />
                                    </div>
                                    <div className="user-info">
                                        <div className="name sans-pro-regular">{prestation?.aide_menagere?.firstname} {prestation?.aide_menagere?.lastname}</div>
                                    </div>
                                    <div className="user-button">
                                        <Badge color={"lightgrey"} className="sans-pro-regular" count={"Aide-ménagère"}></Badge>
                                    </div>
                                </div>
                                <div className="instruction sans-pro-regular">
                                    <a href="#" onClick={() => setShowModalInstructionSpecial(true)}> <img src="/assets/img/instruction.png" style={{ width: "20px", verticalAlign: "top", marginRight: "5px" }} alt="airbnb" className="title-img" />
                                        Laisser des instructions de ménage pour cette prestation</a>
                                    {prestation?.instruction !== null && prestation?.instruction !== "" && <img src="/assets/img/green-check.png" style={{
                                        width: "15px",
                                        verticalAlign: "top",
                                        marginLeft: "15px",
                                        marginTop: "4px"
                                    }} alt="airbnb" className="title-img" />}
                                </div>
                                <div className="send-message sans-pro-regular">
                                    <Button className="disable" style={{ cursor: "default" }}>
                                        <div className="icon">
                                            <img src="./assets/img/sen- email-icon-grey.png" alt="photo" />
                                        </div>
                                        Envoyer un message à l'équipe</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={16} className="content-box-padding-left">
                            <Row>
                                <Col lg={12}>
                                    <Col lg={24} className="content-min-box-padding-left">
                                        <div className="content-box content-box-bottom lit-kit-box">
                                            <div className="mintitle custom-padding sans-pro-regular">
                                                <div style={{ float: "right", marginTop: "-1px" }}>
                                                    <a href="#" onClick={() => setShowModalModifierLit(true)} style={{ fontSize: "12px" }}>Modifier</a>
                                                </div>
                                                Lits à préparer
                                            </div>
                                            <div className="description-controle sans-pro-regular color-A7A7A7">
                                                Precisez le besoin spécifique pour cette prestation <br />
                                                uniquement.
                                            </div>
                                            <div className="info-supp first">
                                                <div className="icone-enveloppe">
                                                    <img src="./assets/img/king size icon.png" alt="icon" />
                                                    <div
                                                        className="counter sans-pro-semi-bold">{prestation?.details_info?.lit_king ?? 0}</div>
                                                </div>
                                                <div className="icone-call">
                                                    <img src="./assets/img/2bed icon.png" alt="icon" />
                                                    <div
                                                        className="counter sans-pro-semi-bold">{prestation?.details_info?.lit_double ?? 0}</div>
                                                </div>
                                                <div className="icone-call2">
                                                    <img src="./assets/img/1bed icon.png" alt="icon" />
                                                    <div
                                                        className="counter sans-pro-semi-bold">{prestation?.details_info?.lit_simple ?? 0}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <br />
                                    <Col lg={24} className="content-min-box-padding-left">
                                        <div className="content-box content-box-bottom lit-kit-box kit-list">
                                            <div className="mintitle custom-padding sans-pro-regular">
                                                <div style={{ float: "right", marginTop: "-1px" }}>
                                                    <a href="#" onClick={() => setShowModalModifierKit(true)} style={{ fontSize: "12px" }}>Modifier</a>
                                                </div>
                                                Kits d'accueil à déposer
                                            </div>
                                            <div className="description-controle sans-pro-regular color-A7A7A7">
                                                Precisez les quantités à déposer pour cette prestation.
                                            </div>
                                            <div className="kit-list">
                                                <Row>
                                                    <Col lg={11}>
                                                        <div className="info-supp">
                                                            <div className="title color-A7A7A7">GRAND FORMAT</div>
                                                            <Row>
                                                                <Col lg={8}>
                                                                    <div className="iconHead">
                                                                        <div className="icone">
                                                                            <img src="./assets/img/Petit déjeuner icon.png" className="iconePTD"
                                                                                style={{ width: "50px", marginTop: "-15px", marginLeft: "-5px" }} alt="icon" />
                                                                        </div>
                                                                        <div className="counter sans-pro-semi-bold">{petitDej}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <div className="iconHead">
                                                                        <div className="icone">
                                                                            <img src="./assets/img/Entretien icon.png" className="iconeSimple" alt="icon" />
                                                                        </div>
                                                                        <div className="counter sans-pro-semi-bold">{entretien}</div>
                                                                    </div>

                                                                </Col>
                                                                <Col lg={8}>
                                                                    <div className="iconHead">
                                                                        <div className="icone">
                                                                            <img src="./assets/img/Hygiène icon.png" className="iconeSimple" alt="icon" />
                                                                        </div>
                                                                        <div className="counter sans-pro-semi-bold">{hygiene}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                    <Col lg={2}>
                                                        <div className="line">

                                                        </div>
                                                    </Col>
                                                    <Col lg={11}>
                                                        <div className="info-supp">
                                                            <div className="title color-A7A7A7">MINI</div>
                                                            <Row>
                                                                <Col lg={8}>
                                                                    <div className="iconHead">
                                                                        <div className="icone">
                                                                            <img src="./assets/img/Mini dej icon.png" className="iconePTDMini"
                                                                                style={{ width: "30px", marginTop: "-3px" }}
                                                                                alt="icon" />
                                                                        </div>
                                                                        <div className="counter sans-pro-semi-bold">{petitDejmini}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <div className="iconHead">
                                                                        <div className="icone">
                                                                            <img src="./assets/img/mini entretien icon.png" className="iconeSimple" alt="icon" />
                                                                        </div>
                                                                        <div className="counter sans-pro-semi-bold">{entretienmini}</div>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <div className="iconHead">
                                                                        <div className="icone">
                                                                            <img src="./assets/img/mini hygiene icon.png" className="iconeSimple" alt="icon" />
                                                                        </div>
                                                                        <div className="counter sans-pro-semi-bold">{hygienemini}</div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Col>
                                <Col lg={12}>
                                    <Col lg={24} className="content-min-box-padding-right">
                                        <div className="content-box content-box-bottom controle-qualite-box">
                                            <div style={{ float: "right", marginTop: "-1px" }}>
                                                <div style={{ textAlign: "right" }}>
                                                    <EyeFilled style={{
                                                        fontSize: "11px",
                                                        verticalAlign: "top",
                                                        marginTop: "5.5px",
                                                    }} />
                                                    <a href="#" onClick={() => setShowModalControle(true)} style={{ fontSize: "10.5px", marginLeft: "6px" }}>Contrôle Qualité</a>
                                                </div>
                                                {prestation?.status === "Terminé" && <div style={{
                                                    textAlign: "center",
                                                    cursor: "pointer",
                                                    display: "inline-block",
                                                    marginLeft: "5px"
                                                }}
                                                    onClick={() => setShowControleSinPrestation(true)}>
                                                    <EyeFilled style={{
                                                        fontSize: "11px",
                                                        verticalAlign: "top",
                                                        marginTop: "6.5px"
                                                    }} /> <a href="#" style={{ fontSize: "10.3px", marginLeft: "2px" }}>Rapport de
                                                        prestation</a>
                                                </div>}
                                            </div>
                                            <div className="mintitle custom-padding sans-pro-regular">
                                                Contrôle qualité
                                            </div>
                                            <div className="description-controle sans-pro-regular color-A7A7A7">
                                                Contrôles que nous faisons en interne <br />
                                                pour garantir la qualité de la prestation.
                                            </div>
                                            <div className="status-progress">
                                                <div className="label"> {getPercent()}%</div>
                                                <div className="progresscontent">
                                                    <Progress percent={getPercent()} showInfo={false} strokeColor="#74DA74" />
                                                </div>
                                            </div>

                                            {/*<div className="bloc-status-performence ">
                                                <Row>
                                                    <Col lg={2}>
                                                        <div className="image-arrow">
                                                            <IoArrowForwardCircleOutline/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={19}>
                                                        <div className="text sans-pro-regular color-7F7F7F">
                                                            Présence confirmée
                                                        </div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <div className="image-status-check">
                                                            <img
                                                                src={prestation?.quality_control?.presence_confirmee ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"}
                                                                alt="image"/>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                                <div className="bloc-status-performence ">
                                                <Row>
                                                <Col lg={2}>
                                            <div className="image-arrow">
                                                <IoArrowForwardCircleOutline/>
                                            </div>
                                    </Col>
                                    <Col lg={19}>
                                        <div className="text sans-pro-regular color-7F7F7F">
                                            Linge & Kit d’accueil prêts
                                        </div>
                                    </Col>
                                    <Col lg={3}>
                                        <div className="image-status-check">
                                            <img
                                                src={prestation?.quality_control?.linge_amenities_deposes ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"}
                                                alt="image"/>
                                        </div>
                                    </Col>
                            </Row>
                </div>
                <div className="bloc-status-performence ifNormal">
                    <Row>
                        <Col lg={2}>
                            <div className="image-arrow">
                                <IoArrowForwardCircleOutline/>
                            </div>
                        </Col>
                        <Col lg={19}>
                            <div className="text sans-pro-regular color-7F7F7F">
                                Prestation démarrée à l’heure
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="image-status-check">
                                <img
                                    src={prestation?.quality_control?.prestation_started_on_time ? "./assets/img/done.png" : "./assets/img/check-grey-circled.png"}
                                    alt="image"/>
                            </div>
                        </Col>
                    </Row>
                </div>*/
                                            }
                                            {/*<div className="linkBottom sans-pro-regular">
                                                <a href="#" onClick={() => setShowModalControle(true)}>Tout voir</a>
                                            </div>*/}
                                        </div>
                                    </Col>
                                    <br />
                                    <Col lg={24} className="content-min-box-padding-right">
                                        <div className="content-box content-box-bottom activite-box scroll-none">
                                            <div className="mintitle sans-pro-regular">
                                                Activité
                                            </div>
                                            {logList?.data?.messages?.length !== 0 && logList?.data?.messages[0]["events_log_action"] === "prestation.create" && <div className="log">
                                                <div className="image">
                                                    <BranchesOutlined />
                                                </div>
                                                <div className="description sans-pro-regular">
                                                    <div className="libelle">La prestation a été créée</div>
                                                    <div
                                                        className="timer">{moment(new Date(logList?.data?.messages[0]["events_log_actionDate"])).format("LLL")}</div>
                                                </div>
                                            </div>}

                                            {prestation?.status !== "Programmé" && prestation?.status !== "Annulé" && prestation?.intervention_debut && <div className="log">
                                                <div className="image">
                                                    <CalendarOutlined />
                                                </div>
                                                <div className="description sans-pro-regular">
                                                    <div className="libelle">Prestation démarrée
                                                    </div>
                                                    <div className="timer">{moment(new Date(prestation?.intervention_debut)).format("LLL")} </div>
                                                </div>
                                            </div>}
                                            {prestation?.status !== "En cours" && prestation?.status !== "Programmé" && prestation?.status !== "Annulé" && prestation?.intervention_fin && <div className="log">
                                                <div className="image">
                                                    <BranchesOutlined />
                                                </div>
                                                <div className="description sans-pro-regular">
                                                    <div className="libelle"> Prestation terminée
                                                    </div>
                                                    <div className="timer">{moment(new Date(prestation?.endDate)).format("LLL")}</div>
                                                </div>
                                            </div>}
                                            {prestation?.status === "Annulé" && prestation?.updtedAt && <div className="log">
                                                <div className="image">
                                                    <BranchesOutlined />
                                                </div>
                                                <div className="description sans-pro-regular">
                                                    <div className="libelle"> Prestation annulée
                                                    </div>
                                                    <div className="timer">{moment(new Date(prestation?.updtedAt)).format("LLL")}</div>
                                                </div>
                                            </div>}

                                            {/*<div className="log">
                                            <div className="image">
                                                <BranchesOutlined/>
                                            </div>
                                            <div className="description sans-pro-regular">
                                                <div className="libelle">La prestation a été créée
                                                    par <span>Brice</span>
                                                </div>
                                                <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                        </div>
                                            <div className="log">
                                            <div className="image">
                                            <CalendarOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a démarré la prestation</div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <BranchesOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a ajouté les <a href="#">photos/vidéos
                                            Avant du logement</a></div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <FileTextOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a terminé la prestation</div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <FileTextOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Martine</span> a ajouté les <a href="#">photos/vidéos
                                            Avant du logement</a></div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>
                                            <div className="log">
                                            <div className="image">
                                            <FileTextOutlined />
                                            </div>
                                            <div className="description sans-pro-regular">
                                            <div className="libelle"><span>Kliner</span> a contrôlé la prestation et
                                            elle a obtenu une note générale de 4.2 sur 5
                                            </div>
                                            <div className="timer">06.12.24 à 10:38</div>
                                            </div>
                                            </div>*/}
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={8}>
                            <div className="content-box content-box-bottom">
                                <div className="mintitle sans-pro-regular">
                                    <div style={{ float: "right", marginTop: "-2px" }}>
                                        <a href="#" onClick={() => setShowModalModifierPrix(true)} style={{ fontSize: "15px" }}>Ajuster</a>
                                    </div>
                                    Clôture
                                </div>
                                <div className="title-ttc sans-pro-regular">Total TTC dû</div>
                                <Row>
                                    <Col lg={19}>
                                        <div className="price sans-pro-regular">€ {coutTTC(user?.profil, prestation, address(user))} (EURO)</div>
                                        <div className="hors-taxe sans-pro-regular color-A7A7A7">Soit {coutHorsTax(user?.profil, prestation)} € Hors Taxes</div>
                                    </Col>
                                    <Col lg={5}>
                                        <Button className="a-regler sans-pro-regular">A régler</Button>
                                    </Col>
                                </Row>
                                <br className="ifNormal" />
                                <Row>
                                    <Col lg={9}>
                                        <div className="card-zone">
                                            <Row>
                                                <Col lg={8}>
                                                    <div className="card-image">
                                                        <img src={getPaymentMethodIcon(defaultPaymentMethod?.data)} alt="check" />
                                                    </div>
                                                </Col>
                                                <Col lg={15}>
                                                    <div className="card-number sans-pro-regular color-A7A7A7">
                                                        ****{getLast4(defaultPaymentMethod?.data)}
                                                    </div>
                                                </Col>
                                                <Col lg={1}>
                                                    <div className="check-image">
                                                        <img src="./assets/img/green-check.png" alt="check" />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col lg={1} />
                                    <Col lg={14}>
                                        <div className="indication-facturation sans-pro-regular color-A7A7A7">
                                            Facturé le 1er du mois suivant ou dès l’atteinte <br />
                                            de votre plafond budgétaire de 500€
                                        </div>
                                    </Col>
                                </Row>

                                <div className={"avis-text sans-pro-regular " + (datePast || prestation?.status !== "Terminé" ? " disabled-block" : "")}>
                                    Comment a été votre expérience sur cette prestation ?
                                </div>
                                <div className={"sticker " + (datePast || prestation?.status !== "Terminé" ? " disabled-block" : "")} onClick={() => datePast ? null : setShowModalEvaluation(true)}>
                                    <Row>
                                        <Col lg={5}><div className={"only-sticker " + (prestation?.quality_control?.customer_stars !== null ? parseInt(prestation?.quality_control?.customer_stars).toString() === "1" ? "active" : "" : "")}>🥲</div></Col>
                                        <Col lg={5}><div className={"only-sticker " + (prestation?.quality_control?.customer_stars !== null ? parseInt(prestation?.quality_control?.customer_stars).toString() === "2" ? "active" : "" : "")}>🙁️</div></Col>
                                        <Col lg={5}><div className={"only-sticker " + (prestation?.quality_control?.customer_stars !== null ? parseInt(prestation?.quality_control?.customer_stars).toString() === "3" ? "active" : "" : "")}>😕</div></Col>
                                        <Col lg={5}><div className={"only-sticker " + (prestation?.quality_control?.customer_stars !== null ? parseInt(prestation?.quality_control?.customer_stars).toString() === "4" ? "active" : "" : "")}>🙂</div></Col>
                                        <Col lg={4}><div className={"only-sticker " + (prestation?.quality_control?.customer_stars !== null ? parseInt(prestation?.quality_control?.customer_stars).toString() === "5" ? "active" : "" : "")}>😍</div></Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <UpdateTime
                        status={showModalCreneau}
                        handleCancel={() => setShowModalCreneau(false)}
                        prestationId={prestation?.id}
                        creneauDebut={prestation?.creneau_intervention_debut}
                        creneauFin={prestation?.creneau_intervention_fin}
                    />
                    <KitDeLingeModalView
                        status={showModalKit}
                        handleCancel={() => setShowModalKit(false)}
                    />
                    <KitAccueilModalView
                        status={showModalAccueil}
                        handleCancel={() => setShowModalAccueil(false)}
                    />
                    <ControleQualiteModalView
                        status={showModalControle}
                        prestaStatus={prestation?.status}
                        handleCancel={() => setShowModalControle(false)}
                        quality={prestation?.quality_control}
                        getPercent={getPercent()}
                    />
                    <InstructionSpecialeModalView
                        status={showModalInstructionSpecial}
                        prestaInstruction={prestation?.instruction}
                        prestationId={prestation?.id}
                        handleCancel={() => setShowModalInstructionSpecial(false)}
                    />
                    <EvaluationModalView
                        idQuality={prestation?.quality_control}
                        prestationId={prestation?.id}
                        status={showModalEvaluation}
                        handleCancel={() => setShowModalEvaluation(false)}
                    />
                    <ModifierKitsModalView
                        idDetailPrestation={prestation?.details_info?.id}
                        idPrestation={prestation?.id}
                        petitDej={petitDej}
                        entretien={entretien}
                        hygiene={hygiene}
                        hygienemini={hygienemini}
                        entretienmini={entretienmini}
                        petitDejmini={petitDejmini}
                        status={showModalModifierKit}
                        handleCancel={() => setShowModalModifierKit(false)}
                    />
                    <ModifierLitsModalView
                        idPrestationDetail={prestation?.details_info?.id}
                        sipmle={prestation?.details_info?.lit_simple}
                        double={prestation?.details_info?.lit_double}
                        king={prestation?.details_info?.lit_king}
                        status={showModalModifierLit}
                        handleCancel={() => setShowModalModifierLit(false)}
                    />
                    <ControleFinPrestationPage
                        controle={prestation?.quality_control}
                        status={showControleSinPrestation}
                        dysfonctionnement={prestation?.dysfunctions}
                        handleCancel={() => setShowControleSinPrestation(false)}
                    />
                    <AjusterPrixPrestationModalView
                        status={showModalModifierPrix}
                        handleCancel={() => setShowModalModifierPrix(false)}
                    />
                </div>
            </Spin>
        </Modal>
    );
};
export default PlanningDetail;
