import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";
import { Layout, Row, Col } from 'antd';
import AuthVerify from "./utils/AuthVerify";
import AppHeader from './components/header';
import Sidebar from './components/sidebar';
import LoginPage from './pages/auth/login';
import DashBoardPage from './pages/dashboard';
import LogementPage from './pages/logements';
import { logout } from "./pages/auth/services/auth-slice";
import EquipePage from './pages/equipes';
import PlanningPage from './pages/planning';
import LogistiquePage from './pages/logistique';
import HommeToutFairePage from './pages/homme-a-tout-faire';
import RapportPage from './pages/rapport';
import ParrainagePage from './pages/parrainage';
import ConciergeriePage from './pages/conciergerie';
import RegisterPage from './pages/auth/register';
import RegisterProfilPage from './pages/auth/register/register-profil-page';
import RegisterSuccessPage from './pages/auth/register/register-success-page';
import SendResetPasswordPage from "./pages/auth/reset-password/send-reset-password";
import ProfilPage from "./pages/profil";
import AppNotification from "./components/notification";
import QuelProfilePage from "./pages/equipes/add-tierce/quel-profil-page";
import ContacterPartenairePage from "./pages/equipes/add-tierce/contacter-partenaire-page";
import RemunerationPartenairePage from "./pages/equipes/add-tierce/remuneration-partenaire-page";
import InviteEnvoyePage from "./pages/equipes/add-tierce/invite-envoye-page";
import ResponsivePage from "./pages/responsive";
import { isMobile } from 'react-device-detect';
import ResetPasswordPage from "./pages/auth/reset-password/reset-password";
import SuspendreModalView from "./pages/dashboard/suspendu";
import { useGetAccountStatusQuery } from "./pages/profil/services/profil-api";
import { getUser } from "./utils/global-var";
import PrestationCatalog from "./pages/planning/prestation-catalog/prestation-catalog";

const { Content } = Layout;



function App() {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const handleLogout = useCallback(() => {
        dispatch(logout());
    }, [dispatch]);

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route exact path="/" element={isMobile ? <ResponsivePage /> : <LoginPage />} />
                    <Route exact path="/register" element={<RegisterPage />} />
                    <Route exact path="/register-profil" element={<RegisterProfilPage />} />
                    <Route exact path="/page-de-confirmation" element={<RegisterSuccessPage />} />
                    <Route exact path="/send-reset-password" element={isMobile ? <ResponsivePage /> : <SendResetPasswordPage />} />
                    <Route path="/prestation/catalog/:id/:status" element={<PrestationCatalog />} />
                    <Route path="/reset-password" element={isMobile ? <ResponsivePage /> : <ResetPasswordPage />} />
                    <Route exact element={isMobile ? <ResponsivePage /> : <PrivateWrapper auth={auth.token} />}>
                        <Route element={<DashboardWrapper />}>
                            <Route exact path="/dashboard" element={<DashBoardPage />} />
                        </Route>

                        <Route element={<LogementWrapper />}>
                            <Route exact path="/logements" element={<LogementPage />} />
                        </Route>

                        <Route element={<EquipeWrapper />}>
                            <Route exact path="/equipes-entretien" element={<EquipePage />} />
                        </Route>

                        <Route element={<PlanningWrapper />}>
                            <Route exact path="/planning-menage" element={<PlanningPage />} />
                        </Route>

                        <Route element={<LogistiqueWrapper />}>
                            <Route exact path="/qualite-logistique" element={<LogistiquePage />} />
                        </Route>

                        <Route element={<HommeAToutFaireWrapper />}>
                            <Route exact path="/homme-a-tout-faire" element={<HommeToutFairePage />} />
                        </Route>

                        <Route element={<RapportWrapper />}>
                            <Route exact path="/rapports" element={<RapportPage />} />
                        </Route>

                        <Route element={<ParrainageWrapper />}>
                            <Route exact path="/parrainage" element={<ParrainagePage />} />
                        </Route>

                        <Route element={<ConciergerieWrapper />}>
                            <Route exact path="/conciergerie-en-reseau" element={<ConciergeriePage />} />
                        </Route>

                        <Route element={<ProfilWrapper />}>
                            <Route exact path="/mon-profil" element={<ProfilPage />} />
                        </Route>
                    </Route>
                    <Route exact element={<PrivateGrandModalWrapper auth={auth.token} />}>
                        <Route element={<AddTierceWrapper />}>
                            <Route exact path="/inviter-un-tierce" element={<QuelProfilePage />} />
                            <Route exact path="/contacter-partenaire" element={<ContacterPartenairePage />} />
                            <Route exact path="/remuneration-partenaire" element={<RemunerationPartenairePage />} />
                            <Route exact path="/invitation-envoyee" element={<InviteEnvoyePage />} />
                        </Route>
                    </Route>
                </Routes>
                <AuthVerify logOut={handleLogout} />
            </BrowserRouter>
        </>

    );
}

const PrivateWrapper = ({ auth }) => {
    const user = getUser();
    const userStatus = useGetAccountStatusQuery(user?.id);

    return auth ? (
        <>
            <Layout style={{ height: '100vh' }}>
                <Sidebar />
                <Layout >
                    <AppHeader />
                    <Content style={{ margin: '0 16px' }}>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
            {/* <SuspendreModalView
                status={userStatus.isSuccess && userStatus.data && userStatus.data.account_status !== "active"}
                unpaidInvoices={userStatus?.data?.past_due_invoices}
                handleCancel={() => false}
            /> */}
        </>

    ) : (<Navigate to="/" />)
};
const PrivateGrandModalWrapper = ({ auth }) => {
    return auth ? (
        <>
            <Layout style={{ height: '100vh' }}>
                <Sidebar />
                <Layout>
                    <Content style={{ margin: '0 16px' }}>
                        <Outlet />
                    </Content>
                </Layout>
            </Layout>
            {/*<AppNotification/>*/}
        </>

    ) : (<Navigate to="/" />)
};

const DashboardWrapper = () => {
    return (
        <div className="dashboard-page" data-aos="fade-in">
            <Row>
                <Col lg={1} />
                <Col lg={22}>
                    <Outlet />
                </Col>
                <Col lg={1} />
            </Row>
        </div>
    );
};
const AddTierceWrapper = () => {
    return (
        <div className="addtierce-page" data-aos="fade-in">
            <Row>
                <Col lg={24}>
                    <Outlet />
                </Col>
            </Row>
        </div>
    );
};

const LogementWrapper = () => {
    return (
        <div className="logement-page" data-aos="fade-in">
            <Outlet />
        </div>
    );
};

const PlanningWrapper = () => {
    return (
        <div className="planning-page" data-aos="fade-in">
            <Row>
                <Col lg={1} />
                <Col lg={22}>
                    <Outlet />
                </Col>
                <Col lg={1} />
            </Row>
        </div>
    );
};

const EquipeWrapper = () => {
    return (
        <div className="equipe-page" data-aos="fade-in">
            <Row>
                <Col lg={3} />
                <Col lg={17}>
                    <Outlet />
                </Col>
                <Col lg={4} />
            </Row>
        </div>
    );
};

const LogistiqueWrapper = () => {
    return (
        <div className="logistique-page" data-aos="fade-in">
            <Outlet />
        </div>
    );
};


const HommeAToutFaireWrapper = () => {
    return (
        <div className="homme-a-tout-faire-page" data-aos="fade-in">
            <Outlet />
        </div>
    );
};

const RapportWrapper = () => {
    return (
        <div className="logistique-page" data-aos="fade-in">
            <Outlet />
        </div>
    );
};


const ParrainageWrapper = () => {
    return (
        <div className="parrainage-page" data-aos="fade-in">
            <Row>
                <Col lg={1} />
                <Col lg={22}>
                    <Outlet />
                </Col>
                <Col lg={1} />
            </Row>
        </div>
    );
};


const ConciergerieWrapper = () => {
    return (
        <div className="conciergerie-page" data-aos="fade-in">
            <Outlet />
        </div>
    );
};
const ProfilWrapper = () => {
    return (
        <div className="profil-page" data-aos="fade-in">
            <Row>
                <Col lg={2} />
                <Col lg={20}>
                    <Outlet />
                </Col>
                <Col lg={2} />
            </Row>
        </div>
    );
};


export default App;
